import { Box, Typography, styled } from '@mui/material';

const StyledBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    margin: `0 ${theme.spacing(10)}`,
  },
}));

const StyledHeaderLine = styled(Box)(({ theme }) => ({
  position: 'relative',
  marginTop: theme.spacing(2),
  '&::before': {
    content: '""',
    position: 'absolute',
    height: theme.spacing(1.5),
    width: theme.spacing(12),
    backgroundColor: theme.palette.warning.light,
    right: '50%',
    borderRadius: 8,
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    height: theme.spacing(1.5),
    width: theme.spacing(12),
    backgroundColor: theme.palette.primary.main,
    left: '50%',
    borderRadius: 8,
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
  },
}));

export function HeaderLine({ title }: { title: string }) {
  return (
    <StyledBox>
      <Typography variant={'h3'} textAlign={'center'}>
        {title}
      </Typography>
      <StyledHeaderLine />
    </StyledBox>
  );
}

export function HeaderBox({ children }: { children: React.ReactNode }) {
  return <StyledBox>{children}</StyledBox>;
}
