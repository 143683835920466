export const blogs = [
  {
    key: 'net_rabbitmq_pub_sub',
    title: 'Asynchronous messaging using Rabbit MQ',
    subTitle: 'Publish/Subscribe messaging model using RabbitMQ',
    author: 'Anjali Sharma',
    image: 'blogimages/RabbitMQ.jpg',
  },
  {
    key: 'dn-channels',
    title: 'Exploring .NET Channels',
    subTitle: 'Asynchronous Communication Made Simple',
    author: 'Pradeep Kodical',
    image: 'blogimages/channels.jpeg',
  },
  {
    key: 'json-in-sql',
    title: 'JSON Function in SQL',
    subTitle: 'Uncover game-changing SQL function.',
    author: 'Sagar Pathak',
    image: 'blogimages/sql-json.jpg',
  },
].sort((a, b) => (Math.random() > 0.5 ? 1 : -1));
