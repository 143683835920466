import { Box, Typography, styled } from '@mui/material';
import { FadeInWhenVisible } from '../../components';

const StyledMainBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(25),
  textAlign: 'center',
}));

export function MainPicture() {
  return (
    <Box
      sx={{
        backgroundImage: `url(${process.env.REACT_APP_BLOB_ROOT}/images/site/mainbg.jpg)`,
        height: '100vh',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        display: 'flex',

        alignItems: 'center',
        flexDirection: 'column-reverse',
      }}
    >
      <FadeInWhenVisible>
        <StyledMainBox>
          <Typography variant={'h2'} color='#fafafa'>
            Transforming Concepts into Tangible Creations
          </Typography>
          <Typography variant={'h3'} color='#efefef' mt={3}>
            We create web and mobile products that can scale effectively.
          </Typography>
        </StyledMainBox>
      </FadeInWhenVisible>
    </Box>
  );
}
