import { AnimatePresence, motion } from 'framer-motion';

export function RotateContent({
  children,
  index,
  style,
}: {
  children: React.ReactNode;
  index: number;
  style?: any;
}) {
  const initial = Math.random() * 60 * (index % 2 === 0 ? 1 : -1);
  return (
    <AnimatePresence>
      <motion.div
        initial='hidden'
        whileInView='visible'
        transition={{ duration: 0.8, delay: 0.4 }}
        variants={{
          visible: { transform: `rotate(0deg)` },
          hidden: { transform: `rotate(${initial}deg)` },
        }}
        viewport={{ once: true }}
        style={style}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
}
