import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Box } from '@mui/material';

export function FadeInWhenVisible(props: any) {
  const { children, delay, y, ...others } = props;
  return (
    <AnimatePresence>
      <motion.div
        initial='hidden'
        whileInView='visible'
        transition={{ duration: 1, delay: delay || 0.3 }}
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: y || '10px' },
        }}
        viewport={{ once: true }}
      >
        <Box {...others}>{children}</Box>
      </motion.div>
    </AnimatePresence>
  );
}
