import { createTheme, responsiveFontSizes } from '@mui/material';

const borderRadius = 8;

const defaultTheme1 = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#D9415D',
    },
    secondary: {
      main: '#32378C',
    },
    error: {
      main: '#F28585',
    },
    warning: {
      main: '#F2C6C2',
    },
    background: {
      paper: '#fff',
      default: '#F2F2F2',
    },
  },
  shape: {
    borderRadius,
  },
  spacing: 8,
  typography: {
    fontFamily: [
      'Poppins',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderTopLeftRadius: borderRadius,
          borderTopRightRadius: borderRadius,
          textTransform: 'none',
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.secondary.light,
          borderTopLeftRadius: borderRadius,
          borderTopRightRadius: borderRadius,
        }),
        scrollButtons: {
          borderRadius,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          borderRadius,
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          textTransform: 'none',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          borderRadius: borderRadius / 2,
          '& > .MuiInputLabel-root': {
            marginLeft: ownerState.InputProps?.startAdornment
              ? theme.spacing(4)
              : 'auto',
          },
        }),
      },
    },
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: borderRadius,
          '& .MuiInputAdornment-root': {
            marginTop: '0 !important',
          },
        }),
        error: ({ theme }) => ({
          border: `2px solid ${theme.palette.error.main}`,
        }),
      },
    },
    MuiInputLabel: {
      defaultProps: {
        disableAnimation: true,
        shrink: true,
      },
      styleOverrides: {
        root: {
          '& .MuiInputLabel-asterisk': {
            display: 'none',
          },
          ':not(&.Mui-required)': {
            marginLeft: 0,
            '&::after': {
              content: '" (Optional)"',
            },
            '& + .MuiOutlinedInput-root': {
              '& fieldset span::after': {
                content: '" (Optional)"',
              },
            },
          },
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          boxShadow:
            'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
        },
      },
    },
  },
});

export const defaultTheme = responsiveFontSizes(defaultTheme1);
