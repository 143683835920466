const devs = [
  {
    name: 'Anjali Sharma',
    img: 'Anjali.jpg',
    role: 'Code Wizard',
  },
  {
    name: 'Sagar Pathak',
    img: 'sagar.png',
    role: 'Software Sorcerer',
  },
  {
    name: 'Shreekesh Murkar',
    img: 'shreekesh.png',
    role: 'Software Crafting Expert',
  },
  {
    name: 'Anjani Devika',
    img: 'anjani.jpg',
    role: 'Code Artificer',
  },
  {
    name: 'Keval Vadoliya',
    img: 'keval.jpg',
    role: 'Mobile Code Artisan',
  },
];

devs.sort((a, b) => (Math.random() > 0.5 ? 1 : -1));

export const developers = [
  ...devs,
  {
    name: 'Pradeep Kodical',
    img: 'pradeep.jpg',
    role: 'Software Craftsman',
  },
];
