import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { defaultTheme } from './theme/defaultTheme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { AppRoutes } from './AppRoutes';

function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
