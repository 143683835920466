import { Box, Button, Divider } from '@mui/material';
import { Page, Section } from '../../components';
import { Postings, JobPosting } from './postings';
import { useParams } from 'react-router-dom';
import { EmailOutlined, ShareOutlined } from '@mui/icons-material';
import { copyToClipboard } from '../../shared/HelperUtil';

function JobItem({ job }: { job: JobPosting }) {
  const {
    jobId,
    jobTitle,
    location,
    responsibilities,
    requirements,
    howToApply,
    joinUs,
  } = job;

  return (
    <Box mb={2}>
      <h1>{jobTitle}</h1>
      <p>
        <strong>Location:</strong> {location}
      </p>

      <div className='section'>
        <h2>Responsibilities:</h2>
        <ul>
          {responsibilities.map((responsibility, index) => (
            <li key={index}>{responsibility}</li>
          ))}
        </ul>
      </div>

      <div className='section'>
        <h2>Requirements:</h2>
        <div>
          <ul>
            {requirements.map((requirement, index) => (
              <li key={index}>{requirement}</li>
            ))}
          </ul>
        </div>
      </div>

      <p>{howToApply}</p>
      <p>{joinUs}</p>
      <Box display={'flex'} columnGap={1}>
        <Button
          LinkComponent={'a'}
          variant='outlined'
          href={`mailto:contact@kiproshamerica.com?subject=Application for ${encodeURIComponent(
            jobTitle
          )}`}
          startIcon={<EmailOutlined />}
        >
          Apply
        </Button>
        <Button
          variant='outlined'
          onClick={() =>
            copyToClipboard(`${window.location.origin}/careers/${jobId}`)
          }
          startIcon={<ShareOutlined />}
          title='copy to clipboard'
        >
          Share
        </Button>
      </Box>
    </Box>
  );
}

export function CareersPage() {
  const { jobId } = useParams();
  return (
    <Box>
      <Page>
        {Postings.filter((p) => p.jobId === jobId || !jobId).map((p, i) => (
          <Section key={i}>
            <JobItem job={p} />
            <Divider />
          </Section>
        ))}
      </Page>
    </Box>
  );
}
