import { SvgIcon, SvgIconProps } from '@mui/material';

export function KiproshAmericaIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      width='280.000000pt'
      height='280.000000pt'
      viewBox='0 0 280.000000 280.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,280.000000) scale(0.100000,-0.100000)'
        fill='#fff'
        stroke='none'
      >
        <path
          d='M0 1400 l0 -1400 1400 0 1400 0 0 1400 0 1400 -1400 0 -1400 0 0
-1400z m2500 10 l0 -1090 -1100 0 -1100 0 0 1090 0 1090 1100 0 1100 0 0
-1090z'
        />
        <path
          d='M400 1400 l0 -1000 29 0 c28 0 35 10 187 310 87 171 161 310 164 310
3 0 75 -139 160 -310 l155 -310 167 0 c93 0 168 2 168 5 0 3 -108 221 -239
484 l-240 479 260 511 c142 281 259 513 259 516 0 3 -69 5 -152 5 l-153 0
-230 -439 -230 -439 -3 439 -2 439 -150 0 -150 0 0 -1000z'
        />
        <path
          d='M1557 2378 c-8 -13 -127 -247 -265 -521 l-251 -498 91 -176 92 -175
169 338 c167 333 170 339 183 309 7 -16 127 -304 268 -639 140 -334 257 -610
259 -612 2 -3 76 -3 164 -2 l161 3 -407 985 c-223 542 -410 991 -414 998 -13
19 -35 14 -50 -10z'
        />
        <path
          d='M762 807 c-13 -23 -66 -124 -119 -224 l-95 -183 181 0 181 0 24 54
24 53 -84 172 c-46 94 -84 171 -86 171 -2 0 -13 -19 -26 -43z'
        />
      </g>
    </SvgIcon>
  );
}
