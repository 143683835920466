import { Box, Link, Typography, alpha } from '@mui/material';

export function Copyright() {
  return (
    <Box
      sx={{
        backgroundColor: (theme) => alpha('#dedede', 0.6),
        p: 2,
      }}
    >
      <Typography variant='body2' color='text.secondary' align='center'>
        {'Copyright © '}
        <Link color='inherit' href='https://www.kacoders.com/'>
          KA Coders
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    </Box>
  );
}
