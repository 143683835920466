import { SvgIcon, SvgIconProps } from '@mui/material';

export function EnthusiasmIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      width='1024.000000pt'
      height='1024.000000pt'
      viewBox='0 0 1024.000000 1024.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,1024.000000) scale(0.100000,-0.100000)'
        stroke='none'
      >
        <path
          d='M4910 7988 c-79 -28 -130 -65 -170 -126 -61 -92 -63 -110 -68 -524
-4 -432 -2 -451 68 -531 68 -77 220 -137 309 -121 70 12 189 81 239 138 60 69
64 100 60 543 -3 384 -4 400 -25 445 -47 103 -171 187 -288 195 -45 3 -76 -2
-125 -19z m135 -244 c42 -16 45 -46 45 -413 l0 -350 -29 -20 c-37 -26 -61 -26
-95 0 l-26 20 0 365 c0 351 1 365 20 384 20 20 54 26 85 14z'
        />
        <path
          d='M7205 7543 c-134 -30 -231 -81 -311 -165 -168 -176 -231 -424 -160
-632 38 -111 93 -201 168 -272 76 -73 127 -104 233 -140 68 -24 96 -28 190
-28 184 0 319 50 441 167 110 104 179 253 191 412 11 153 -58 340 -172 463
-76 81 -200 159 -294 184 -62 16 -234 23 -286 11z m197 -254 c233 -50 354
-282 267 -513 -29 -80 -87 -139 -177 -184 -72 -34 -83 -37 -167 -37 -76 0 -99
5 -147 27 -79 37 -142 98 -181 177 -30 61 -32 73 -32 166 0 89 3 107 28 160
75 161 235 241 409 204z'
        />
        <path
          d='M2625 7513 c-115 -16 -211 -67 -288 -153 -86 -96 -119 -183 -118
-314 1 -280 252 -494 524 -448 315 54 474 380 331 679 -37 78 -103 142 -194
188 -74 37 -184 58 -255 48z m158 -290 c22 -15 51 -48 64 -73 21 -38 24 -55
20 -108 -16 -221 -278 -276 -379 -79 -46 92 2 223 102 275 60 31 137 25 193
-15z'
        />
        <path
          d='M6240 6107 c-181 -62 -320 -256 -320 -446 0 -78 26 -173 60 -221 23
-32 23 -34 11 -144 -23 -198 -79 -388 -163 -548 l-34 -66 -23 89 c-58 227
-154 376 -313 486 -203 140 -443 175 -683 99 -190 -60 -366 -215 -446 -392
-43 -94 -70 -202 -72 -291 l-2 -78 -58 95 c-89 147 -165 410 -182 628 -7 85
-6 93 20 146 82 167 44 389 -87 519 -101 99 -220 143 -366 134 -198 -12 -367
-155 -427 -362 -33 -112 -2 -279 71 -385 l34 -50 0 -161 c0 -319 75 -641 215
-920 53 -105 156 -253 280 -402 147 -177 229 -290 273 -374 l37 -73 -81 -74
c-45 -42 -96 -98 -114 -126 -29 -45 -34 -64 -41 -148 -10 -111 -20 -141 -66
-199 -38 -47 -77 -73 -148 -99 -42 -15 -84 -18 -255 -18 -195 -1 -207 -2 -253
-25 -114 -56 -178 -161 -185 -301 -4 -79 -2 -90 28 -153 39 -84 71 -122 124
-150 36 -19 55 -21 148 -20 83 2 111 -1 122 -12 8 -8 34 -16 58 -16 43 -2 43
-2 -12 -9 -30 -5 -241 -8 -468 -9 -453 -1 -456 -2 -487 -61 -33 -64 -6 -156
55 -187 20 -10 461 -13 2105 -13 1960 0 2082 1 2115 18 46 23 75 79 65 129 -9
51 -61 101 -114 109 -45 7 -46 14 -15 76 13 25 18 26 121 33 58 4 158 5 221 3
l114 -5 32 44 c111 158 48 403 -133 517 -68 43 -132 51 -313 37 -121 -9 -175
-10 -211 -2 -151 35 -259 160 -272 316 -3 39 -16 95 -27 127 -26 67 -117 184
-181 233 -43 33 -46 37 -36 63 28 75 104 182 225 318 243 273 365 472 450 730
74 225 104 421 104 678 l0 129 41 44 c158 171 137 446 -46 622 -133 128 -330
175 -495 118z m-2564 -242 c56 -16 105 -61 128 -117 28 -67 17 -186 -21 -230
-31 -36 22 -519 74 -677 32 -97 101 -246 144 -308 98 -143 196 -239 409 -397
223 -165 382 -227 585 -229 225 -2 425 79 625 252 36 31 81 69 100 85 143 114
275 276 354 435 98 195 145 374 170 651 l13 145 -32 38 c-123 144 -14 368 177
367 115 -1 213 -77 232 -180 14 -78 -52 -201 -115 -218 l-31 -7 -2 -280 c-3
-318 -12 -383 -77 -580 -77 -231 -174 -393 -369 -617 -271 -312 -357 -469
-390 -716 -36 -263 61 -631 222 -842 120 -158 257 -384 242 -399 -8 -8 -2096
-14 -2161 -6 l-53 6 58 112 c32 62 99 171 149 242 129 185 181 297 239 515 22
83 25 320 5 400 -22 92 -81 230 -140 328 -31 51 -129 186 -220 300 -90 114
-184 237 -208 274 -97 150 -176 322 -212 466 -48 187 -56 258 -56 497 0 127 4
242 8 257 6 25 4 29 -26 41 -43 19 -94 78 -107 126 -27 101 48 223 162 262 65
22 63 22 124 4z m1466 -736 c131 -27 238 -105 311 -226 50 -83 69 -145 74
-250 7 -112 -9 -168 -73 -264 -153 -231 -477 -302 -716 -158 -197 119 -281
323 -218 531 79 265 349 424 622 367z'
        />
        <path
          d='M7466 3838 c-25 -18 -66 -50 -90 -71 -42 -36 -46 -38 -83 -28 -113
31 -298 17 -345 -26 -64 -58 -64 -131 -2 -250 26 -48 55 -101 66 -116 l20 -28
-25 -27 c-59 -64 -86 -152 -62 -208 22 -53 42 -73 82 -80 38 -7 233 13 271 27
20 8 30 2 75 -48 80 -87 121 -113 178 -113 89 0 123 56 159 257 l13 70 53 23
c109 45 157 95 170 178 6 36 3 43 -30 80 -20 22 -69 57 -109 77 -39 21 -73 39
-74 40 -2 1 -12 35 -22 77 -27 103 -58 166 -92 183 -48 25 -101 19 -153 -17z
m35 -310 c8 -32 21 -45 86 -87 l77 -49 -48 -29 c-98 -57 -116 -85 -116 -177
l0 -39 -22 20 c-53 48 -130 86 -177 87 l-47 1 4 62 c4 65 -11 105 -73 188
l-22 30 68 -3 c73 -4 174 17 224 47 27 15 30 16 33 2 2 -9 8 -33 13 -53z'
        />
        <path
          d='M7054 2016 c-43 -19 -64 -53 -64 -104 0 -56 22 -103 59 -125 26 -15
65 -17 335 -17 357 0 364 1 397 76 34 75 15 135 -53 170 -39 20 -628 20 -674
0z'
        />
      </g>{' '}
    </SvgIcon>
  );
}
