import { Box, Divider } from '@mui/material';
import { Page, Section } from '../../components';
import { FundamentalBeliefs } from './FundamentalBeliefs';
import { MainPicture } from './MainPicture';
import { CoderProfiles } from './CoderProfiles';
import { Blogs } from './Blogs';
import { Contacts } from './Contacts';

export function LandingPage() {
  return (
    <Box>
      <MainPicture />
      <Page>
        <Section>
          <FundamentalBeliefs />
        </Section>
        <Divider />
        <Section>
          <CoderProfiles />
        </Section>
        <Divider />
        <Section>
          <Blogs />
        </Section>
      </Page>
      <Section dark={true}>
        <Contacts />
      </Section>
    </Box>
  );
}
