import { Box, styled } from '@mui/material';
import { MainAppBar } from './MainAppBar';
import { Copyright } from './Copyright';
import { Outlet } from 'react-router-dom';

const StyledMainBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  minHeight: 'calc(100vh - 64px)',
  flexDirection: 'column',
}));

export function Layout() {
  return (
    <Box>
      <MainAppBar />
      <StyledMainBox>
        <Box flex={1}>
          <Outlet />
        </Box>
        <Copyright />
      </StyledMainBox>
    </Box>
  );
}
