import { Box, Paper, Typography, styled } from '@mui/material';
import { RotateContent } from '../../components/animation/RotateContent';
import { HeaderLine } from '../../components';
import { developers } from '../../content/developers';

const StyledCoderProfiles = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  columnGap: theme.spacing(1),
  rowGap: theme.spacing(1),
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(2),
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1),

  img: {
    flex: 1,
    borderRadius: theme.spacing(1),
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  '.MuiBox-root': {
    backgroundColor: '#fff',
    backdropFilter: 'blur(6px)',

    padding: theme.spacing(1),

    textAlign: 'center',
  },
}));

export function CoderProfiles() {
  return (
    <Box mb={8} mt={8}>
      <HeaderLine title={'Our Team'} />

      <StyledCoderProfiles mt={8}>
        {developers.map((p, i) => (
          <RotateContent key={i} index={i} style={{ display: 'flex' }}>
            <StyledPaper>
              <img
                src={`${process.env.REACT_APP_BLOB_ROOT}/images/profiles/${p.img}`}
                alt={p.name}
                width={200}
              />
              <Box>
                <Typography variant={'body1'} color={'primary'}>
                  {p.name}
                </Typography>
                <Typography variant={'caption'} color={'secondary'}>
                  {p.role}
                </Typography>
              </Box>
            </StyledPaper>
          </RotateContent>
        ))}
      </StyledCoderProfiles>
    </Box>
  );
}
