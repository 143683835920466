import { SvgIcon, SvgIconProps } from '@mui/material';

export function CollaborationIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      width='1024.000000pt'
      height='1024.000000pt'
      viewBox='0 0 1024.000000 1024.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,1024.000000) scale(0.100000,-0.100000)'
        stroke='none'
      >
        <path
          d='M4760 8247 c-119 -41 -294 -179 -392 -311 -109 -146 -157 -287 -165
-481 -8 -199 32 -348 138 -511 60 -91 233 -266 311 -314 57 -34 58 -36 58 -81
0 -99 -1 -101 -108 -142 -91 -35 -366 -97 -432 -97 -85 0 -112 -24 -126 -108
-11 -67 0 -90 48 -103 72 -19 346 10 524 57 52 13 97 24 100 24 7 0 0 -577 -7
-584 -2 -2 -80 -6 -174 -8 -150 -3 -175 -6 -211 -25 -54 -28 -110 -93 -143
-162 l-26 -56 0 -425 0 -425 23 -48 c31 -62 96 -127 155 -156 42 -21 67 -24
215 -28 l167 -6 -3 -96 c-2 -53 1 -120 8 -148 13 -61 26 -68 121 -68 83 1 109
21 111 88 1 46 2 48 5 13 3 -22 12 -46 21 -54 16 -12 15 -13 -3 -7 -16 5 -18
4 -7 -7 9 -9 55 -13 159 -14 81 -1 141 1 134 6 -11 6 -11 8 0 12 8 3 27 -4 42
-16 39 -29 135 -29 174 0 48 36 57 67 55 187 l-3 108 166 -2 c165 -1 167 -1
223 28 37 19 69 45 94 78 69 91 69 92 66 571 l-3 429 -27 51 c-40 76 -73 110
-134 140 -55 27 -60 27 -219 25 l-164 -2 0 105 c0 121 -17 168 -71 199 -35 21
-117 25 -149 8 -11 -6 -93 -12 -188 -13 -148 -2 -154 -3 -48 -5 66 -2 123 -7
127 -11 4 -4 -46 -6 -110 -5 -73 1 -106 -2 -87 -7 17 -4 24 -8 18 -9 -14 -1
-7 -57 10 -78 7 -10 4 -13 -9 -14 -18 0 -18 -1 1 -8 l20 -8 -20 -5 c-11 -3 16
-7 60 -8 44 -1 103 -3 130 -4 28 -1 53 3 57 8 4 6 8 -32 8 -84 1 -133 33 -210
106 -258 32 -21 45 -22 218 -22 l185 0 31 -37 31 -38 -3 -367 -3 -367 -28 -29
-28 -29 -168 5 c-93 3 -185 2 -205 -2 -49 -9 -113 -68 -126 -114 -5 -21 -10
-94 -10 -163 l0 -126 -31 -7 c-85 -17 -259 -5 -288 20 -2 2 -7 64 -11 138 -6
123 -9 138 -33 175 -48 73 -57 75 -281 81 l-200 5 -23 34 c-23 34 -23 36 -23
385 0 333 1 353 20 384 11 18 32 37 47 43 15 6 93 9 178 8 184 -3 226 11 274
86 48 76 60 167 44 349 -6 76 -6 77 -41 91 -46 20 -78 18 -129 -5 l-43 -19 0
139 c0 76 -4 150 -9 163 -9 23 -5 28 49 61 91 56 129 107 193 258 58 138 65
169 46 212 -13 27 -78 65 -156 91 -138 44 -298 208 -386 394 l-41 86 -1 125
c0 145 19 221 82 330 53 90 182 222 278 285 120 78 147 118 121 180 -25 59
-89 84 -156 62z'
        />
        <path
          d='M3615 8071 c-79 -19 -115 -66 -115 -150 0 -89 54 -151 145 -165 31
-5 50 -1 84 16 66 34 86 67 86 141 -1 77 -24 120 -80 147 -43 21 -69 23 -120
11z'
        />
        <path
          d='M5123 7912 c-91 -18 -161 -55 -233 -120 -96 -86 -139 -177 -160 -335
-15 -108 -5 -159 53 -271 53 -103 127 -178 221 -222 302 -144 608 -14 717 303
14 43 18 79 16 172 -2 114 -3 119 -41 195 -59 120 -156 208 -286 260 -65 26
-205 35 -287 18z m221 -242 c52 -24 110 -80 139 -133 33 -64 31 -184 -5 -252
-40 -76 -87 -113 -183 -140 -205 -60 -391 132 -330 340 34 119 178 223 289
210 27 -4 68 -15 90 -25z'
        />
        <path
          d='M3250 7589 c-45 -18 -80 -60 -80 -95 0 -57 21 -84 125 -157 209 -148
361 -344 426 -547 33 -101 37 -289 10 -402 -64 -269 -260 -474 -521 -545 -88
-24 -297 -23 -395 1 -87 22 -252 101 -325 156 -77 57 -203 182 -268 265 -97
124 -121 145 -164 145 -87 0 -131 -91 -89 -184 48 -110 230 -305 393 -422 160
-116 446 -204 661 -204 183 0 397 52 527 127 174 102 335 333 401 573 19 71
23 111 23 245 1 185 -4 228 -44 347 -81 240 -247 456 -502 651 -79 60 -117 70
-178 46z'
        />
        <path
          d='M6912 7578 c-56 -9 -87 -32 -210 -152 -273 -267 -417 -564 -413 -856
3 -205 14 -256 90 -424 65 -143 223 -325 356 -410 64 -41 65 -47 65 -247 l0
-149 42 -21 c23 -12 58 -37 77 -55 20 -19 39 -34 43 -34 5 0 22 -19 39 -42 17
-24 40 -48 51 -54 11 -6 31 -40 45 -75 36 -89 59 -118 112 -142 143 -63 285
47 260 202 -10 65 -48 132 -96 168 -31 24 -46 28 -102 28 -36 0 -74 -4 -83 -9
-17 -8 -18 2 -18 141 l0 150 103 6 c313 20 592 148 800 367 186 196 234 305
179 402 -26 45 -84 60 -137 36 -16 -7 -57 -56 -98 -117 -160 -234 -308 -350
-543 -424 -184 -58 -388 -51 -527 19 -194 98 -328 247 -393 439 -23 70 -27 97
-28 215 -1 114 3 148 22 215 58 199 204 404 391 550 52 41 97 84 106 104 23
46 15 105 -20 141 -31 33 -54 39 -113 28z'
        />
        <path
          d='M7318 7183 c-40 -13 -88 -34 -105 -44 -18 -11 -38 -17 -45 -13 -7 4
-10 4 -6 -1 4 -4 -9 -20 -30 -35 -89 -66 -149 -161 -178 -286 -23 -98 -14
-171 24 -205 38 -34 104 -47 135 -26 21 14 26 11 92 -50 38 -36 89 -76 113
-90 43 -26 118 -38 128 -22 3 5 -13 9 -36 9 -23 0 -51 5 -63 11 -52 28 -147
114 -168 154 -22 41 -22 48 -12 109 21 124 78 207 175 255 112 56 234 38 324
-47 88 -84 112 -168 80 -276 -27 -88 -88 -150 -175 -176 -20 -6 -53 -16 -73
-22 -21 -6 -35 -15 -32 -20 3 -5 12 -7 19 -4 8 3 16 0 19 -7 3 -8 5 -7 5 3 1
8 12 -2 26 -23 14 -20 23 -42 19 -49 -4 -6 -3 -8 3 -5 10 6 58 -47 58 -63 0
-5 4 -12 9 -17 5 -5 6 -3 2 5 -7 11 -4 11 13 0 12 -7 21 -16 21 -20 0 -12 105
44 150 81 69 56 88 80 131 166 48 98 59 169 43 286 -13 100 -35 151 -98 237
-86 116 -215 189 -351 198 -38 3 -86 6 -107 8 -21 2 -67 -6 -110 -21z'
        />
        <path
          d='M2735 7195 c-5 -2 -42 -13 -81 -24 -122 -35 -240 -136 -301 -255 -33
-64 -56 -194 -48 -268 12 -119 75 -239 169 -324 181 -165 475 -175 660 -23 48
40 123 147 147 210 60 157 37 352 -56 485 -98 137 -233 205 -405 203 -41 0
-79 -2 -85 -4z m180 -239 c181 -86 224 -317 84 -457 -51 -51 -91 -70 -159 -76
-106 -10 -209 43 -262 134 -51 88 -49 210 5 290 83 121 215 165 332 109z'
        />
        <path
          d='M5302 6750 c-55 -25 -80 -75 -63 -130 19 -63 113 -203 186 -275 114
-115 262 -179 519 -225 205 -37 299 -23 312 46 7 38 -10 95 -34 117 -10 9 -63
22 -128 32 -238 34 -390 92 -487 184 -46 44 -61 65 -73 110 -35 121 -73 161
-151 161 -21 0 -57 -9 -81 -20z'
        />
        <path
          d='M2061 5345 c-144 -32 -229 -150 -215 -299 10 -108 88 -209 185 -241
66 -22 168 -16 224 13 93 48 155 148 155 252 0 121 -63 220 -167 266 -60 27
-90 28 -182 9z'
        />
        <path
          d='M7995 5354 c-106 -27 -128 -38 -167 -77 -89 -91 -114 -218 -64 -322
56 -114 174 -184 276 -165 104 20 181 72 220 151 68 134 18 297 -115 375 -39
22 -125 44 -150 38z'
        />
        <path
          d='M2875 5301 c-43 -27 -82 -70 -99 -113 -20 -46 -21 -148 -2 -191 28
-69 111 -113 197 -105 70 7 114 41 151 116 29 58 30 67 24 133 -8 85 -25 117
-79 153 -52 33 -145 37 -192 7z'
        />
        <path
          d='M6392 5200 c-37 -23 -66 -81 -58 -115 3 -12 20 -39 37 -61 34 -44 35
-72 5 -174 -21 -71 -13 -121 26 -159 74 -73 183 -30 245 97 24 49 28 69 28
142 0 75 -3 91 -28 136 -16 29 -51 73 -77 99 -42 41 -55 48 -97 52 -36 3 -56
-1 -81 -17z'
        />
        <path
          d='M3656 5169 c-72 -63 -115 -197 -97 -300 20 -113 69 -182 139 -194 94
-16 143 25 150 126 3 46 8 58 23 62 25 7 59 82 59 132 0 22 -11 63 -24 90 -39
82 -87 115 -171 115 -35 0 -52 -6 -79 -31z'
        />
        <path
          d='M2950 4523 c-166 -14 -389 -76 -520 -144 -123 -63 -284 -185 -386
-291 -67 -70 -82 -91 -88 -126 -12 -78 34 -142 102 -142 41 0 64 12 122 62 25
22 50 43 55 47 6 4 41 35 79 69 127 116 313 224 440 257 33 8 114 22 180 31
133 17 216 11 360 -26 90 -23 132 -43 213 -105 114 -85 172 -162 240 -320 24
-55 55 -112 69 -126 14 -15 60 -43 102 -63 77 -36 114 -45 477 -114 153 -30
298 -80 309 -107 3 -8 8 -37 12 -64 6 -44 4 -50 -15 -56 -51 -16 -182 -105
-241 -165 -205 -205 -287 -446 -259 -759 18 -208 81 -358 228 -540 90 -112
227 -208 355 -250 177 -57 261 -68 399 -51 167 20 293 71 434 177 183 138 303
312 357 518 48 188 40 391 -24 573 -52 146 -184 321 -310 409 -41 29 -84 58
-94 66 -16 11 -18 21 -12 70 l7 57 62 32 c106 53 176 74 387 118 248 51 259
55 348 114 69 46 70 47 92 119 66 220 242 400 450 461 118 35 225 46 338 35
276 -28 451 -116 698 -351 128 -121 132 -123 208 -118 64 4 102 59 90 129 -5
29 -29 59 -123 151 -216 213 -476 355 -731 401 -111 20 -386 18 -480 -3 -148
-34 -307 -112 -401 -196 -111 -99 -223 -265 -266 -392 -25 -75 -5 -66 -263
-121 -401 -85 -563 -172 -655 -352 -25 -47 -34 -57 -50 -52 -45 12 -134 16
-198 7 l-67 -8 -24 52 c-41 88 -84 142 -157 195 l-69 51 -2 136 -2 137 -3
-133 c-3 -122 -5 -133 -21 -127 -78 33 -176 59 -305 80 -84 14 -211 39 -284
56 l-131 31 -7 35 c-9 48 -74 170 -128 241 -67 86 -176 193 -245 239 -132 88
-466 163 -652 146z m2383 -1408 c94 -37 166 -86 242 -160 75 -74 106 -122 151
-232 79 -200 55 -436 -62 -613 -121 -181 -249 -273 -449 -321 -167 -40 -313
-15 -460 78 -86 55 -133 100 -191 180 -154 216 -182 437 -86 683 74 191 235
340 424 395 132 38 323 33 431 -10z'
        />
        <path
          d='M2675 4004 c-183 -50 -340 -207 -366 -365 -11 -69 -6 -182 11 -241
16 -56 104 -181 166 -236 101 -90 241 -139 364 -128 278 27 444 200 443 461
-1 173 -43 286 -142 385 -119 119 -311 169 -476 124z m229 -235 c174 -65 221
-308 84 -435 -64 -60 -163 -81 -257 -55 -52 15 -133 87 -164 146 -30 57 -30
164 1 222 65 123 202 173 336 122z'
        />
        <path
          d='M7685 3785 c-66 -19 -78 -36 -70 -105 13 -98 6 -189 -16 -241 -59
-136 -234 -198 -362 -127 -22 13 -60 48 -84 78 -24 30 -48 60 -53 67 -16 19
-81 33 -119 25 -73 -16 -95 -96 -51 -187 29 -60 129 -164 187 -194 156 -79
317 -77 474 5 188 99 309 336 280 549 -20 142 -54 166 -186 130z'
        />
        <path
          d='M3830 3412 c-31 -15 -39 -27 -72 -122 -53 -145 -71 -181 -144 -287
-114 -164 -229 -272 -375 -354 -124 -70 -169 -128 -155 -203 8 -40 51 -84 91
-92 56 -11 231 101 407 260 164 148 265 290 359 504 36 82 43 110 47 179 4 77
3 83 -21 107 -31 31 -85 35 -137 8z'
        />
        <path
          d='M6274 3099 c-35 -39 -37 -103 -7 -163 61 -120 124 -196 302 -360 133
-124 232 -194 284 -203 75 -12 127 35 127 115 0 53 -33 99 -106 147 -150 99
-285 230 -381 369 -59 86 -85 105 -148 112 -44 5 -53 3 -71 -17z'
        />
        <path
          d='M6610 2201 c-85 -26 -151 -93 -176 -177 -30 -105 -5 -201 73 -276 47
-46 95 -66 172 -74 49 -6 67 -3 112 18 115 53 172 134 172 248 1 157 -117 272
-277 269 -28 0 -62 -4 -76 -8z'
        />
      </g>
    </SvgIcon>
  );
}
