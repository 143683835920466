import {
  AppBar,
  Button,
  Toolbar,
  Typography,
  alpha,
  styled,
} from '@mui/material';
import { KiproshAmericaIcon } from '../icons/KiproshAmericaIcon';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.main, 0.95),
}));

export function MainAppBar() {
  return (
    <StyledAppBar position='sticky'>
      <Toolbar>
        <KiproshAmericaIcon />
        <Typography
          variant='h6'
          noWrap
          color='inherit'
          sx={{ flexGrow: 1, ml: 1 }}
        >
          KACoders
        </Typography>
        <Button
          variant={'text'}
          color='inherit'
          href='https://blogs.kacoders.com'
          target='_blank'
        >
          Blogs
        </Button>
        <Button
          variant={'text'}
          color='inherit'
          href='/careers'
          target='_blank'
        >
          Careers
        </Button>
        <Button
          variant={'text'}
          color='inherit'
          href='https://time.kacoders.com'
          target='_blank'
        >
          Timetrack
        </Button>
      </Toolbar>
    </StyledAppBar>
  );
}
