import { Box, Theme } from '@mui/material';

export const blackBG = {
  background: (theme: Theme) => theme.palette.common.black,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyContent: 'center',
  boxShadow:
    'rgb(0 0 0 / 24%) 0px 0px 2px 0px, rgb(0 0 0 / 24%) 0px 16px 32px -4px',
};

export const lightBG = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyContent: 'center',
};

export function Section({
  dark,
  children,
}: {
  dark?: boolean;
  children: React.ReactNode;
}) {
  return (
    <Box
      component={'section'}
      p={2}
      sx={{
        ...(dark ? blackBG : lightBG),
      }}
    >
      {children}
    </Box>
  );
}
