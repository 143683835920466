import { Box, styled } from '@mui/material';
import { motion } from 'framer-motion';

const StyledBox = styled(Box)(({ theme }) => ({
  margin: '0 auto',
  maxWidth: '100%',
  [theme.breakpoints.up('md')]: {
    maxWidth: '80%',
  },
  [theme.breakpoints.up('xl')]: {
    maxWidth: '60%',
  },
}));

export function Page({ children }: any) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.6 }}
    >
      <StyledBox>{children}</StyledBox>
    </motion.div>
  );
}
