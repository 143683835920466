import { Box, Divider, Typography, styled } from '@mui/material';
import {
  CollaborationIcon,
  CompassionIcon,
  EnthusiasmIcon,
  HonestyIcon,
} from '../../components/icons';
import { FadeInWhenVisible, HeaderLine, HeaderBox } from '../../components';

const fundamentalValues = [
  {
    icon: <CompassionIcon />,
    title: 'Compassion',
    description: `In order to excel as perceptive and respectful professionals, and to lead effectively, 
      it is crucial for us to empathize and put ourselves in your shoes.
      A nurturing work environment empowers us to better serve all stakeholders.`,
  },
  {
    icon: <HonestyIcon />,
    title: 'Responsibility and Honesty',
    description: `We operate with utmost openness and integrity. 
        We fulfill our commitments and take ownership of our work and decisions.`,
  },
  {
    icon: <CollaborationIcon />,
    title: 'Inclusivity and Collaborative Unity',
    description: ` We wholeheartedly endorse and champion equality, inclusiveness,
      and diversity. We support each other's personal and professional
      growth, and we function as a cohesive, unified team.`,
  },
  {
    icon: <EnthusiasmIcon />,
    title: 'Excellence and Enthusiasm',
    description: `We provide value through exceptional service quality, high
      performance, and innovative solutions. We firmly believe that
      passion leads to excellence, and excellence yields satisfaction.`,
  },
];

const StyledFundamentalBox = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: '1fr 1fr 1fr 1fr',
  gridGap: theme.spacing(2),

  [theme.breakpoints.up('sm')]: {
    margin: `0 ${theme.spacing(10)}`,
  },

  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr 1fr',
  },

  '.MuiSvgIcon-root': {
    fontSize: '4rem',
  },
}));

function FundamentalBelief({
  icon,
  title,
  description,
}: {
  icon: any;
  title: string;
  description: string;
}) {
  return (
    <FadeInWhenVisible>
      <Box sx={{ p: 3 }}>
        <Box
          display='flex'
          alignItems={['center', 'flex-start']}
          textAlign={['center', 'left']}
          flexDirection={'column'}
          rowGap={2}
        >
          {icon}
          <Typography variant={'h5'} color={'primary'}>
            {title}
          </Typography>
          <Typography variant={'body1'}>{description}</Typography>
        </Box>
      </Box>
    </FadeInWhenVisible>
  );
}

export function FundamentalBeliefs() {
  return (
    <Box
      textAlign={'center'}
      mt={3}
      display={'flex'}
      flexDirection={'column'}
      rowGap={4}
    >
      <HeaderLine title={'Our Fundamental Beliefs'} />
      <HeaderBox>
        <Typography>
          Our foundational principles shape our corporate identity, providing
          ongoing inspiration and guidance. We remain steadfast in upholding
          these values, consistently integrating them into our work and
          fostering them in our interactions with customers and colleagues.
        </Typography>
      </HeaderBox>
      <Divider />

      <StyledFundamentalBox>
        {fundamentalValues.map((a, i) => (
          <FundamentalBelief
            key={i}
            icon={a.icon}
            title={a.title}
            description={a.description}
          />
        ))}
      </StyledFundamentalBox>
    </Box>
  );
}
