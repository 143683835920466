import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { HeaderLine, HeaderBox } from '../../components';

import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';

import { blogs } from '../../content/blogs';

function getInitials(name: string) {
  name = name + ' . .';
  return name
    .split(' ')
    .reduce((p, c) => p + c[0], '')
    .substring(0, 2);
}
function BlogCard({
  cardData,
  onClick,
}: {
  cardData: any;
  onClick: () => void;
}) {
  return (
    <Card sx={{ minWidth: 300, height: '100%' }}>
      <CardHeader
        sx={{ textAlign: 'left' }}
        avatar={
          <Avatar sx={{ bgcolor: 'primary.main' }}>
            {getInitials(cardData.author)}
          </Avatar>
        }
        action={
          <IconButton onClick={onClick}>
            <OpenInNewOutlinedIcon />
          </IconButton>
        }
        title={cardData.title}
        subheader={cardData.author}
      />
      <CardMedia
        component='img'
        height='280'
        image={`${process.env.REACT_APP_BLOB_ROOT}/images/${cardData.image}`}
        alt={cardData.title}
      />
      <CardContent>
        <Typography variant='body2' color='text.secondary'>
          {cardData.subTitle}
        </Typography>
      </CardContent>
    </Card>
  );
}

export function Blogs() {
  const doLaunch = (p: any) => {
    window.open(`${process.env.REACT_APP_BLOGS_URL}/posts/${p.key}`, `_blank`);
  };

  return (
    <Box
      mb={8}
      textAlign={'center'}
      mt={3}
      display={'flex'}
      flexDirection={'column'}
      rowGap={4}
    >
      <HeaderLine title={'Blog Highlights'} />
      <HeaderBox>
        <Typography>
          Our ethos revolves around making valuable contributions to the
          community through activities such as crafting blog posts,
          orchestrating and coordinating knowledge-sharing meetups,
          participating in conferences, and creating products aimed at enhancing
          efficiency and productivity.
        </Typography>
      </HeaderBox>

      <Grid
        container
        spacing={2}
        alignItems={'stretch'}
        justifyContent={'center'}
      >
        {blogs.map((p: any, i) => (
          <Grid item xs={12} md={6} xl={4}>
            <BlogCard cardData={p} onClick={() => doLaunch(p)} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
