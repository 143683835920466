export interface JobPosting {
  jobId: string;
  jobTitle: string;
  location: string;
  description?: string;
  responsibilities: string[];
  requirements: string[];
  howToApply: any;
  joinUs: string;
}

export const Postings: JobPosting[] = [
  {
    jobId: 'P658',
    jobTitle: 'Senior AI Engineer',
    location: 'USA',
    responsibilities: [
      'Develop, fine-tune, and deploy Generative AI and LLM-based solutions to meet business and technical requirements.',
      'Collaborate with cross-functional teams to design and implement AI models and algorithms.',
      'Lead the development of multi-model AI solutions and prototypes using state-of-the-art technologies like Gemini and GPT-4.',
      'Conduct research and stay up-to-date with the latest advancements in AI, machine learning, and deep learning.',
      'Optimize and improve existing AI models for performance, scalability, and accuracy.',
      'Mentor and guide junior AI engineers, providing technical expertise and leadership.',
      'Communicate complex AI concepts to stakeholders in a clear and understandable manner.',
      'Participate in code reviews, design discussions, and contribute to the continuous improvement of our AI development processes.',
    ],
    requirements: [
      'Minimum of 5 years of experience in AI/ML development with a strong focus on Generative AI and LLM fine-tuning.',
      'Proven hands-on experience with developing multi-model AI-based solutions or prototypes using technologies such as Gemini or GPT-4.',
      'Deep understanding of machine learning algorithms, neural networks, and natural language processing (NLP).',
      'Proficiency in programming languages such as Python, TensorFlow, PyTorch, or similar frameworks.',
      'Strong problem-solving skills and the ability to work independently and as part of a team.',
      'Excellent communication and interpersonal skills.',
    ],
    howToApply: (
      <>
        Interested candidates are invited to submit their resume and a cover
        letter to [contact@kiproshamerica.com ]. Please include examples of your
        previous work, specifically highlighting your experience.
      </>
    ),
    joinUs:
      'Join our team and be part of a collaborative team that thrives on innovation and technical excellence!',
  },
  // {
  //   jobId: 'P629',
  //   jobTitle: 'QA Consultant',
  //   location: 'Des Plaines, IL USA',
  //   description:
  //     'As a QA consultant, your role would primarily be to coordinate with PM, QA leads, and QA team members on schedules and statuses, collaborate with the development team on requirements, create test plans and test cases, execute test cases, validate results, and report statuses on a daily basis. Good exposure to creating basic to mid-level SQL queries is a must as the role involves creating validation SQL scripts, execution, and verification of results. Experience in testing desktop applications, web applications, and batch processes is required. Exposure to project management/Test management/Defect management tools JIRA and ALM is essential.',
  //   responsibilities: [
  //     'Coordinate with PM, QA leads, and QA team members on schedules and statuses.',
  //     'Collaborate with the development team on requirements.',
  //     'Create test plans and test cases.',
  //     'Execute test cases, validate results, and report statuses on a daily basis.',
  //     'Create basic to mid-level SQL queries for validation scripts.',
  //     'Execute and verify the results of SQL scripts.',
  //     'Test desktop applications, web applications, and batch processes.',
  //     'Use project management tools like JIRA and ALM for test suite maintenance and defect management.',
  //     'Interpret requirements and create a test strategy.',
  //     'Map requirements to test cases and defects for triaging.',
  //     'Prioritize and organize tasks to complete project deliverables on time and meet release timelines.',
  //     'Coordinate user acceptance testing and interact with project stakeholders, providing testing status.',
  //     'Work on the Windows Platform and test desktop applications, web applications, batch, and API processes.',
  //     'Work with the onsite-offshore model.',
  //     'Troubleshoot and assist in issue or defect resolution.',
  //   ],
  //   requirements: [
  //     'Strong knowledge of software quality assurance best practices & methodologies.',
  //     'Strong experience performing Functional, Integration, Regression, E2E, and Performance testing based on project needs.',
  //     'Experience writing basic to mid-level SQL queries and ability to read and interpret complex SQL queries and stored procedures.',
  //     'Strong communication skills and the ability to interact with project stakeholders on change requirements, asking the right questions, and seeking clarifications.',
  //     'Exposure to working with JIRA and ALM for test suite maintenance and defect management.',
  //     'Ability to interpret requirements, create test strategy, test cases, and map requirements to test cases and defects for triaging.',
  //     'Experience working with projects following Agile, Waterfall, Iterative Software Development methodologies.',
  //     'Experience coordinating user acceptance testing and the ability to interact with project stakeholders, providing testing status.',
  //     'Knowledge of working on the Windows Platform and experience testing desktop applications, web applications, batch, and API processes.',
  //     'Experience working with the onsite-offshore model.',
  //     'Ability to prioritize and organize tasks to complete project deliverables on time and meet release timelines.',
  //     'Ability to troubleshoot and assist in issue or defect resolution.',
  //     'Ability to think through and come up with Automated Solutions using tools like Selenium.',
  //     'Experience working with performance testing tools.',
  //   ],
  //   howToApply: (
  //     <>
  //       Interested candidates are encouraged to send their resume and a cover
  //       letter to [contact@kiproshamerica.com ]. Please include examples of your
  //       previous work, showcasing your expertise in QA consulting and testing
  //       methodologies.
  //     </>
  //   ),
  //   joinUs:
  //     'Join our team and be part of a dynamic environment where your skills in QA consulting will make a significant impact!',
  // },
  {
    jobId: 'P632',
    jobTitle: '.NET Developer - Backend Web API (ReactJS, AWS)',
    location: 'Remote, India',
    responsibilities: [
      'Design, develop, and maintain robust backend Web APIs using .NET technologies.',
      'Collaborate closely with frontend developers to ensure effective communication.',
      'Implement and optimize cloud-based solutions on AWS for scalability and reliability.',
      'Work on data modeling and database interactions for efficient data storage and retrieval.',
      'Participate in code reviews, debugging, and troubleshooting to maintain code quality.',
      'Collaborate with DevOps teams to implement CI/CD pipelines for efficient deployment and monitoring.',
      'Stay abreast of emerging technologies and industry best practices.',
    ],
    requirements: [
      'Proven experience as a .NET Developer with expertise in backend Web API development.',
      'Strong proficiency in C# and .NET framework.',
      'Hands-on experience with AWS services.',
      'In-depth knowledge of ReactJS and its integration with backend APIs.',
      'Solid understanding of RESTful API design principles and best practices.',
      'Experience with relational databases, SQL, and ORM frameworks.',
      'Excellent problem-solving and communication skills.',
      'Ability to work independently and collaboratively within a team.',
      'Experience with AWS Lambda for serverless computing.',
      'Familiarity with containerization technologies like Docker and Kubernetes.',
      'Knowledge of GraphQL for efficient data fetching.',
      'Exposure to state management libraries like Redux in ReactJS applications.',
      'Previous involvement in building responsive and user-friendly interfaces.',
      'AWS certification in related areas.',
    ],
    howToApply: (
      <>
        Interested candidates are invited to submit their resume and a cover
        letter to [contact@kiproshamerica.com ]. Please include examples of your
        previous work, specifically highlighting your experience with backend
        Web API development for ReactJS applications.
      </>
    ),
    joinUs:
      'Join our team and be part of a collaborative team that thrives on innovation and technical excellence!',
  },
  {
    jobId: 'P654',
    jobTitle: 'ReactJS Developer',
    location: 'Remote, India',
    responsibilities: [
      'Develop and implement user interfaces for web applications using ReactJS.',
      'Collaborate with the design team to translate UI/UX design wireframes into responsive and efficient code.',
      'Build reusable components and front-end libraries for future use.',
      'Optimize applications for maximum speed and scalability.',
      'Ensure the technical feasibility of UI/UX designs and implement responsive designs.',
      'Work closely with back-end developers to integrate front-end components with server-side logic.',
      'Collaborate with cross-functional teams to define, design, and ship new features.',
      'Stay updated on emerging trends and technologies in front-end development.',
      'Troubleshoot, debug, and resolve issues to ensure smooth performance.',
    ],
    requirements: [
      'Proven experience as a ReactJS Developer or similar role.',
      'Strong proficiency in JavaScript, including DOM manipulation and the JavaScript object model.',
      'Thorough understanding of ReactJS and its core principles.',
      'Experience with popular ReactJS workflows (such as Redux).',
      'Familiarity with RESTful APIs and integrating with back-end services.',
      'Knowledge of modern front-end build pipelines and tools.',
      'Experience with common front-end development tools, such as Babel, Webpack, npm, etc.',
      'Strong understanding of web markup, including HTML5 and CSS3.',
      'Ability to write clean, well-documented, and efficient code.',
      'Excellent problem-solving and communication skills.',
      'Experience with back-end development and server-side languages (Node.js, Python, etc.).',
      'Familiarity with testing libraries/frameworks (Jest, Enzyme, etc.).',
      'Experience with version control systems (Git).',
      'Knowledge of authentication and authorization concepts.',
      'Understanding of CI/CD pipelines for automated deployment.',
    ],
    howToApply: (
      <>
        If you're passionate about creating engaging and seamless user
        experiences, we'd love to hear from you. Please submit your resume and a
        cover letter to [ contact@kiproshamerica.com ], showcasing your ReactJS
        projects and detailing your contributions.
      </>
    ),
    joinUs:
      'Join our team and be a key player in shaping the future of web development at Kiprosh America Inc',
  },
];
