import { SvgIcon, SvgIconProps } from '@mui/material';

export function HonestyIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      width='1024.000000pt'
      height='1024.000000pt'
      viewBox='0 0 1024.000000 1024.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,1024.000000) scale(0.100000,-0.100000)'
        stroke='none'
      >
        <path
          d='M5060 8235 c-201 -39 -333 -100 -472 -219 -103 -88 -167 -170 -228
    -291 -197 -392 -105 -842 242 -1188 54 -53 131 -119 171 -145 l73 -48 -30 -82
    -31 -82 -121 0 c-192 0 -333 -43 -466 -141 -158 -117 -238 -271 -255 -488 l-6
    -84 -20 24 c-10 13 -103 112 -206 219 -292 306 -428 448 -515 539 -45 46 -107
    100 -138 121 -70 46 -174 73 -277 73 -64 -1 -83 -5 -141 -35 l-68 -35 -153
    153 c-85 85 -168 161 -185 169 -85 40 -198 -8 -231 -97 -18 -50 -16 -129 4
    -165 10 -18 82 -97 160 -176 88 -88 139 -147 134 -153 -6 -5 -25 -38 -43 -74
    -29 -57 -33 -75 -33 -145 0 -67 5 -90 28 -139 37 -80 124 -209 186 -275 l50
    -54 -16 -31 c-68 -132 -55 -326 32 -471 47 -77 86 -118 159 -166 153 -101 326
    -108 488 -21 l47 25 83 -79 c46 -43 161 -158 255 -254 94 -96 230 -227 301
    -290 l130 -115 -41 -7 c-23 -4 -87 -14 -142 -23 -158 -26 -318 -88 -471 -184
    l-49 -30 -6 24 c-10 36 -61 105 -118 158 -57 53 -153 103 -217 113 -64 9 -121
    -22 -357 -200 -114 -86 -250 -186 -302 -222 -125 -86 -129 -94 -130 -260 l0
    -130 42 -85 c64 -129 374 -609 731 -1134 22 -33 101 -152 175 -265 74 -113
    145 -215 158 -227 29 -26 147 -63 201 -63 55 0 81 16 273 165 150 116 421 319
    512 383 56 40 54 53 -34 260 -5 11 175 13 1048 10 974 -3 1059 -2 1121 14 164
    42 199 61 635 343 257 166 1444 957 1508 1005 165 125 265 305 265 476 0 157
    -50 275 -164 389 -122 121 -241 165 -444 165 -179 0 -180 0 -638 -307 -525
    -352 -607 -408 -792 -537 -93 -64 -158 -103 -160 -96 -3 7 -25 40 -51 74 l-46
    61 35 31 c19 17 106 81 192 143 124 88 157 117 162 139 10 53 6 1246 -5 1327
    -15 114 -32 166 -91 272 -39 71 -71 112 -129 168 -146 139 -315 201 -588 214
    l-134 6 -12 35 c-7 19 -24 58 -38 87 l-25 52 67 57 c243 209 382 410 464 669
    71 226 67 379 -14 552 -116 243 -389 457 -689 538 -245 65 -468 87 -610 60z
    m351 -301 c212 -36 390 -111 496 -208 80 -73 147 -174 179 -268 23 -70 26 -91
    21 -153 -17 -192 -123 -400 -289 -563 -93 -91 -129 -116 -251 -169 -221 -98
    -347 -96 -547 8 -210 108 -355 285 -436 529 -23 72 -27 102 -28 195 0 95 3
    121 27 191 78 228 251 391 468 438 88 19 247 19 360 0z m192 -1747 c26 -50 70
    -122 97 -158 40 -55 60 -73 114 -99 60 -30 75 -33 213 -41 162 -9 241 -26 315
    -66 67 -36 139 -111 174 -180 l29 -58 2 -635 3 -635 -106 -95 c-57 -52 -127
    -113 -154 -135 -35 -28 -50 -47 -50 -64 0 -19 -4 -22 -22 -17 -13 3 -339 6
    -725 6 l-703 0 0 243 c0 267 -7 313 -61 425 -54 111 -127 193 -407 455 l-83
    77 3 213 c3 242 5 250 91 343 88 96 139 114 352 123 228 10 245 21 362 226
    l68 121 90 -18 c50 -9 106 -15 125 -12 50 8 155 41 187 58 16 8 30 15 33 15 3
    1 27 -41 53 -92z m-2744 -36 c21 -11 110 -96 202 -193 90 -95 216 -227 279
    -293 103 -107 384 -403 559 -587 l65 -68 -29 -33 c-16 -17 -79 -83 -139 -144
    l-110 -113 -91 88 c-49 48 -116 111 -147 139 -54 48 -58 54 -58 96 0 59 -44
    207 -83 280 -17 33 -65 94 -107 136 -63 65 -87 82 -154 110 -72 30 -88 33
    -175 31 -53 0 -109 -2 -125 -3 -26 -2 -37 8 -97 87 -64 84 -134 199 -134 221
    0 14 220 222 260 247 37 22 37 22 84 -1z m76 -842 c26 -7 56 -28 85 -58 125
    -128 73 -311 -88 -311 -87 0 -181 75 -208 166 -13 47 -13 56 1 91 21 50 51 82
    100 104 47 22 57 22 110 8z m1370 -551 c39 -44 96 -112 127 -151 l58 -72 0
    -268 0 -268 -37 28 c-21 15 -102 82 -181 148 -79 66 -159 132 -178 147 -19 14
    -63 53 -97 86 -34 33 -73 70 -87 81 l-25 20 168 166 c92 91 170 165 175 164 4
    0 39 -36 77 -81z m3997 -363 c150 -52 218 -228 145 -377 -35 -70 -76 -105
    -367 -300 -339 -229 -380 -256 -650 -434 -267 -175 -659 -433 -721 -475 -20
    -13 -70 -47 -110 -75 -101 -70 -187 -112 -277 -135 -74 -19 -120 -19 -1226
    -20 l-1149 0 -126 123 c-241 235 -312 332 -340 468 -6 30 -22 89 -36 130 -13
    41 -33 107 -44 147 l-19 71 87 51 c47 27 108 59 134 70 73 31 226 71 314 81
    43 4 575 10 1183 11 1062 3 1107 2 1144 -16 58 -28 96 -94 96 -167 0 -50 -4
    -62 -30 -92 -49 -55 -71 -57 -676 -55 -404 1 -549 -1 -577 -10 -70 -24 -87
    -105 -38 -182 51 -81 23 -77 666 -79 546 -2 573 -1 647 18 105 29 147 52 206
    117 46 50 70 99 73 145 0 8 35 38 77 67 586 393 1325 884 1352 898 92 47 165
    53 262 20z m-5342 -686 c71 -116 181 -384 199 -484 6 -33 15 -100 20 -148 16
    -139 38 -176 203 -344 79 -80 233 -231 343 -337 110 -105 201 -194 203 -198 2
    -4 -30 -34 -70 -66 -40 -33 -149 -122 -242 -198 l-169 -138 -29 35 c-29 34
    -182 260 -572 844 -290 434 -345 520 -388 613 -21 45 -38 86 -38 90 0 13 483
    380 502 382 3 0 20 -23 38 -51z'
        />
        <path
          d='M3493 7386 c-26 -23 -28 -31 -32 -110 l-3 -86 -72 0 c-89 0 -130 -21
    -145 -77 -15 -52 -14 -61 9 -108 25 -52 70 -75 149 -75 l59 0 4 -102 c2 -66 9
    -111 18 -125 18 -27 59 -43 113 -43 32 0 46 6 72 34 38 40 46 79 38 175 l-6
    69 75 -1 c56 -1 82 3 101 16 40 26 60 73 54 124 -8 74 -52 106 -166 119 l-63
    7 5 42 c8 58 -8 109 -44 139 -43 36 -125 37 -166 2z'
        />
        <path
          d='M7364 7403 c-12 -2 -32 -15 -45 -29 -21 -23 -24 -36 -26 -116 l-2
    -91 -88 2 c-103 2 -145 -16 -169 -74 -18 -44 -18 -65 2 -115 23 -57 54 -70
    164 -70 l90 0 0 -90 c0 -114 13 -142 76 -166 42 -16 50 -16 87 -3 66 23 81 53
    82 164 l0 95 86 0 c73 0 90 3 114 22 54 42 69 118 35 173 -26 43 -83 65 -165
    65 l-65 0 0 70 c0 95 -17 133 -69 153 -40 17 -64 19 -107 10z'
        />
        <path
          d='M7660 6034 c-42 -18 -59 -47 -66 -115 l-7 -69 -99 0 c-92 0 -101 -2
    -123 -25 -51 -50 -23 -169 47 -203 14 -6 59 -15 100 -18 l74 -7 10 -100 c10
    -94 13 -102 43 -129 26 -23 40 -28 85 -28 50 0 56 3 91 41 l37 41 -7 93 -7 93
    81 0 c93 -1 133 17 163 72 36 66 3 140 -71 160 -20 5 -66 12 -103 15 l-68 7 6
    37 c7 44 -14 92 -50 116 -25 17 -111 28 -136 19z'
        />
      </g>
    </SvgIcon>
  );
}
