import { AnimatePresence } from 'framer-motion';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Layout } from './components';
import { LandingPage } from './pages/home/LandingPage';
import { CareersPage } from './pages/careers/CareersPage';

export function AppRoutes() {
  const location = useLocation();
  /*exitBeforeEnter*/
  return (
    <AnimatePresence mode='wait'>
      <Routes location={location} key={location.pathname}>
        <Route path={`${process.env.PUBLIC_URL}/`} element={<Layout />}>
          <Route path={`/`} element={<LandingPage />} />
          <Route path={`/careers`} element={<CareersPage />} />
          <Route path={`/careers/:jobId`} element={<CareersPage />} />
        </Route>
      </Routes>
    </AnimatePresence>
  );
}
