import { SvgIcon, SvgIconProps } from '@mui/material';

export function CompassionIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      width='728.000000pt'
      height='728.000000pt'
      viewBox='0 0 728.000000 728.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
        stroke='none'
      >
        <path
          d='M2057 4839 c-38 -5 -103 -21 -145 -35 -98 -32 -397 -188 -417 -217
-21 -30 -19 -62 8 -91 41 -46 52 -43 254 58 103 52 217 103 253 113 60 17 124
18 860 18 740 0 799 -1 860 -18 96 -27 177 -69 265 -137 l78 -60 503 0 c491 0
505 1 524 20 31 31 27 93 -6 119 -26 21 -36 21 -499 21 -422 0 -474 2 -486 16
-45 55 -209 141 -334 176 -79 22 -85 22 -865 24 -432 1 -816 -2 -853 -7z'
        />
        <path
          d='M1250 4473 c-8 -3 -262 -134 -565 -290 -591 -306 -613 -320 -657
-417 -30 -69 -31 -181 -1 -247 28 -63 81 -120 140 -149 40 -21 62 -25 138 -25
l90 0 672 238 c369 130 673 237 675 237 2 0 2 -15 0 -34 -11 -92 58 -234 143
-295 92 -66 81 -65 620 -71 l490 -5 35 -26 c19 -15 58 -38 85 -51 103 -51 124
-53 520 -53 359 0 372 1 445 23 41 13 109 42 150 65 l75 42 386 5 c363 5 388
6 408 24 28 26 29 88 1 116 -19 19 -33 20 -427 20 l-408 0 -74 -45 c-147 -89
-156 -90 -561 -90 -348 0 -356 0 -413 23 -31 13 -81 42 -110 65 l-52 42 -505
5 -505 5 -45 27 c-101 59 -126 191 -53 279 58 68 62 69 516 69 331 0 413 3
435 14 43 23 192 181 192 204 0 37 -22 71 -52 81 -43 15 -81 -5 -153 -78 l-59
-61 -398 0 c-434 0 -448 -2 -533 -60 -54 -37 -1541 -560 -1592 -560 -81 0
-153 87 -138 167 12 68 46 89 609 379 297 153 548 286 560 295 31 25 27 85 -8
115 -27 24 -46 28 -76 17z'
        />
        <path
          d='M1569 3557 c-64 -29 -203 -221 -285 -391 -147 -308 -176 -675 -79
-1010 30 -102 57 -161 80 -170 55 -21 115 14 115 68 0 16 -13 67 -29 115 -40
120 -61 254 -61 391 0 311 107 593 314 831 71 81 76 133 15 164 -33 18 -36 18
-70 2z'
        />
        <path
          d='M3742 3117 c-29 -31 -28 -62 7 -166 82 -244 81 -538 -1 -784 -41
-123 -30 -161 45 -174 29 -4 40 -1 60 20 27 26 63 129 92 257 22 101 31 373
16 482 -23 158 -76 333 -112 369 -27 27 -80 25 -107 -4z'
        />
        <path
          d='M2205 3087 c-114 -30 -198 -100 -249 -207 -27 -58 -31 -75 -31 -155
0 -141 17 -169 294 -470 127 -137 243 -258 258 -267 34 -22 113 -23 152 -3 45
23 471 496 511 568 34 60 35 65 35 172 0 106 -1 112 -34 173 -112 206 -371
261 -562 119 l-27 -19 -35 27 c-80 60 -215 87 -312 62z m141 -158 c32 -8 64
-29 110 -70 83 -76 104 -77 178 -9 75 67 110 84 176 84 70 1 133 -33 174 -92
38 -54 47 -146 19 -204 -15 -31 -443 -513 -449 -505 -1 1 -97 106 -214 232
-117 127 -221 243 -231 260 -25 41 -33 103 -19 155 20 76 119 158 193 160 10
0 38 -5 63 -11z'
        />
        <path
          d='M598 3040 c-21 -13 -32 -53 -54 -190 -32 -212 -13 -648 32 -717 28
-44 101 -42 130 2 15 24 15 35 1 148 -32 244 -30 408 9 620 16 86 16 90 -3
115 -22 30 -83 41 -115 22z'
        />
        <path
          d='M4410 3023 c-17 -22 -20 -36 -16 -63 45 -255 51 -462 22 -672 -17
-119 -17 -129 -2 -153 29 -44 102 -46 130 -2 45 69 64 505 32 717 -29 185 -37
200 -110 200 -26 0 -40 -7 -56 -27z'
        />
        <path
          d='M3584 1833 c-124 -142 -144 -169 -144 -196 0 -41 15 -63 50 -76 43
-14 75 0 124 56 119 135 138 182 91 228 -37 38 -81 34 -121 -12z'
        />
        <path
          d='M1118 1830 c-77 -13 -148 -40 -228 -84 l-75 -41 -386 -5 c-363 -5
-388 -6 -408 -24 -28 -26 -29 -88 -1 -116 19 -19 33 -20 427 -20 l408 0 74 45
c147 89 156 90 561 90 347 0 356 0 412 -23 31 -13 83 -43 114 -68 l57 -44 218
0 c193 0 219 2 239 18 30 24 30 86 1 116 -20 20 -35 21 -214 26 -188 5 -193 6
-227 31 -43 32 -107 65 -169 87 -38 13 -107 16 -401 18 -195 1 -376 -1 -402
-6z'
        />
        <path
          d='M4730 1774 c-35 -14 -1348 -474 -1352 -474 -2 0 -2 15 0 34 6 52 -25
156 -64 213 -38 56 -115 113 -179 134 -27 9 -96 14 -206 14 -150 0 -169 -2
-188 -19 -25 -23 -28 -82 -7 -112 13 -17 31 -20 178 -24 154 -5 166 -6 208
-32 101 -59 126 -191 53 -279 -58 -68 -62 -69 -516 -69 -331 0 -413 -3 -435
-14 -43 -23 -192 -181 -192 -204 0 -37 22 -71 52 -81 43 -15 81 5 153 78 l59
61 398 0 c434 0 448 2 533 60 54 37 1541 560 1592 560 54 0 101 -32 125 -86
25 -59 18 -104 -25 -149 -36 -37 -1658 -881 -1760 -916 -111 -37 -187 -40
-962 -36 -683 3 -755 5 -810 21 -93 28 -175 70 -260 136 l-78 60 -503 0 c-491
0 -505 -1 -524 -20 -31 -31 -27 -93 6 -119 26 -21 36 -21 499 -21 422 0 474
-2 486 -16 45 -55 209 -141 334 -176 79 -22 89 -22 840 -26 814 -4 892 0 1024
44 41 14 458 223 926 466 650 336 860 449 892 480 61 60 88 126 87 218 0 87
-22 146 -77 207 -55 61 -117 87 -212 90 -44 1 -87 0 -95 -3z'
        />
      </g>
    </SvgIcon>
  );
}
